@import '../../vendor/styles/variables.css';

.sw {
  &__body {
    padding: 16px;
  }

  &__roomConfigBody {
    max-height: 80vh;
    border-radius: 20px 20px 0 0;
  }

  &__search {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 48px;
  }
}

.searchInputModal {
  background: #f7f7f7;
}

.roomConfigModal {
  border-radius: 20px 20px 0 0;
}
